import React, { useState } from "react";
import { Link } from "react-router-dom";
import logoimg from '../Images/medkaro 1.svg'
import './Navbar.css'


function Navbar() {
    const [toggle, settoggle] = useState(true);

  return (
    <div className="container-fluid navbar-container">
          <div className="">

              <nav class="navbar navbar-expand-lg navbar-light navbar-div1 d-md-flex d-none">
                  <Link class="navbar-brand" to='/'>
                      <img src={logoimg} className="navbar-logo1" alt="" />
                  </Link>
                  <button
                      class="navbar-toggler"
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarNav"
                      aria-controls="navbarNav"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                  >
                      <span class="navbar-toggler-icon"></span>
                  </button>
                  
                      

              </nav>
          </div>





























          {/* // mobile Nav Bar */}

          <nav class="navbar mobile-navbar-div1 d-flex d-lg-none navbar-expand-lg navbar-light ">
              

              <Link class="navbar-brand text-center ml-0 mx-auto" to="/">
                  <img src={logoimg} alt="" className="mobile-logo-img" />
              </Link>

             

              <div
                  class={`${toggle
                          ? "navbar-collapse mobile-navbar-div2"
                          : "navbar-collapse mobile-navbar-div2 show"
                      } `}
                  id="navbarSupportedContent"
              >
                  <ul class="navbar-nav mx-auto">
                      <li className="nav-item mobile-nav-item home1">
                          <Link
                              class="nav-link"
                              to="/"
                              onClick={() => {
                                  settoggle(!toggle);
                              }}
                          >
                            Home
                          </Link>
                      </li>
                       <li className="nav-item mobile-nav-item home1">
                          <Link
                              class="nav-link"
                              to="/"
                              onClick={() => {
                                  settoggle(!toggle);
                              }}
                          >
                        Contact
                          </Link>
                      </li>

                     
                  </ul>
              </div>
          </nav>
    </div>
  )
}

export default Navbar
