import React from 'react'
import './Home.css'
import playstorelogo1 from '../Images/appstore.svg'
import playstorelogo2 from '../Images/googleplay.svg'
import riderimg from '../Images/undraw_on_the_way_re_swjt 1.png'


function Home() {
    return (
        <div>
            <div className='container-fluid home-banner-div1'>
                <div className='home-container112'>
                    <div>
                        <h1 className='home-banner-text1'>Medicine delivery in just 15 minutes</h1>
                        <p className='home-banner-text2'>Order any medicine at your doorstep 24/7, <br/> now delivering to Jodhpur Park, Lake Gardens, Golf Green and Jadavpur</p>
                        <div className='home-banner-btn1'>
                            <a href="https://api.whatsapp.com/send?phone=918100078269" ><div>Order Now on WhatsApp</div></a>
                        </div>
                        <div className='home-banner-playstore-btn'>
                            <a href=""><img src={playstorelogo1} alt="" /></a>
                            <a href=""><img src={playstorelogo2} className='mobile-reponsive' alt="" /></a>

                        </div>
                    </div>
                    <div className=' home-rider-img1'>
                        <img src={riderimg} className='img-fluid' alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home
